<template>
  <div></div>
</template>
<script>
import { authService } from '@/apis/auth.s';

export default {
  mounted() {
    try {
      authService.logout();
      localStorage.clear();
      this.$router.push({ name: 'home' });
    } catch (error) {
      console.log(error.response);
    }
  },
};
</script>
